<template>
  <filter-bar-wrapper>
    <div class="table-filter-bar">
      <div class="table-filter-nav">
        <nav>
          <ul>
            <li v-for="option in options" :key="option" :class="activeSelection === option ? 'active' : ''">
              <a @click="() => onChangeSelection(option)">
                {{$t(`${i18nPath}.${option}`)}}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </filter-bar-wrapper>
</template>

<script>
import {defineComponent, ref} from 'vue';
import VueTypes from 'vue-types';
import {FilterBarWrapper} from './style';

export default defineComponent({
  name: 'TableFilterBar',
  props: {
    options: VueTypes.array.required,
    default: VueTypes.string,
    i18nPath: VueTypes.string.required
  },
  emits: ['update:filterValue'],
  components: {FilterBarWrapper},
  setup(props, context) {
    const activeSelection = ref(props.default || props.options[0]);

    const onChangeSelection = (value) => {
      activeSelection.value = value;
      context.emit('update:filterValue', value);
    };

    return {
      activeSelection,
      onChangeSelection
    };
  }
});
</script>
