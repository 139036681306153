<template>
  <div>
    <app-header :routes="routes"
                :buttons="buttons"
                @header-action="handleInviteUser"/>
    <Main>
      <a-row :gutter="15">
        <a-col :xs="24" class="table-search">
          <a-row :gutter="[10,0]" type="flex" justify="start">
            <a-col :xs="24" :sm="10" :md="8" :lg="8" :xl="8" :xxl="5">
              <filter-bar :options="filterBarOptions" i18nPath="models.user.status"
                v-model:filterValue="userStatus" />
            </a-col>
            <a-col :xs="24" :sm="14" :md="16" :lg="16" :xl="16" :xxl="19">
              <a-row :gutter="{ xs: 0, sm: 0, md: 0, lg: 10 }" type="flex" justify="end">
                <a-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14" :xxl="14">
                  <a-select v-model:value="selectedPermissions.value"
                            :allow-clear="true"
                            :filter-option="permissions"
                            :placeholder="$t('layout.users.invite.permissions')"
                            :max-tag-count="1"
                            :show-search="false"
                            :show-arrow="$_.isEmpty(selectedPermissions.value)"
                            mode="multiple"
                            class="permissions-filter">
                    <a-select-option v-for="permission in permissions" :key="permission.id" :value="permission.id">
                      <span>{{permission.displayName}}</span>
                    </a-select-option>
                  </a-select>
                </a-col>
                <a-col :xs="24" :sm="24" :md="24" :lg="8" :xl="6" :xxl="6">
                  <a-input-search v-model:value="searchValue" :placeholder="$t('actions.search')" />
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-col>
        <a-col :xxl="24" :xl="24" :md="24" :xs="24">
          <sd-cards>
            <users-table
              :permissions="selectedPermissions"
              :searchValue="searchValue"
              :userStatus="userStatus"
            />
          </sd-cards>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script>
import {
  computed, defineComponent, reactive, ref
} from 'vue';
import {Main} from '@/components/shared/styledComponents/Main';
import UsersTable from '@/components/users/Table';
import router from '@/routes';
import {useStore} from 'vuex';
import FilterBar from '@/components/tables/FilterBar';
import {useAbility} from '@casl/vue';
import {useI18n} from 'vue-i18n';

export default defineComponent({
  name: 'CompanyUsers',
  components: {
    Main,
    UsersTable,
    FilterBar
  },
  setup() {
    const {t} = useI18n();
    const routes = [{
      name: 'ClientUsers',
      breadcrumbName: t('models.user.entity', 2)
    }];

    const {
      state, getters, dispatch
    } = useStore();
    dispatch('getPermissions');

    const currentUser = computed(() => state.session.currentUser);

    const selectedPermissions = reactive({value: []});
    const permissions = computed(() => getters.permissions);
    const searchValue = ref('');

    const buttons = useAbility().can('invite', 'user') ? [{
      action: 'newUser', icon: 'plus', label: 'layout.users.invite.title'
    }] : [];

    const handleInviteUser = () => {
      router.push({name: `${routePrefix.value}InviteUser`});
    };

    const routePrefix = computed(() => {
      switch (currentUser.value.type) {
      case 'BackOfficeUser':
        return 'BackOffice';
      case 'ClientUser':
        return 'Client';
      default:
        return 'Agent';
      }
    });

    const filterBarOptions = ['all', 'active', 'archived'];
    const userStatus = ref('all');

    return {
      routes,
      selectedPermissions,
      permissions,
      searchValue,
      buttons,
      filterBarOptions,
      userStatus,
      handleInviteUser
    };
  }
});
</script>

<style lang="scss">
.permissions-filter {
  width: 100%;
}
.permissions-filter>.ant-select-selector{
  height: 40px;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 25px !important;
}
</style>
